<template>
  <v-container>
    <!-- <v-navigation-drawer
      right
      :value="dialog"
      :height="this.best_height"
      :width="this.best_width"
      app
      dark
    > -->

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      light
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card :loading="loading_status" loader-height="10" loading-text="Cargando ...">
          <v-card-title class="pa-0">
            <v-toolbar>
              <v-tabs v-model="tab">
                <v-tab key="contact">
                  <v-icon left> mdi-account </v-icon>
                </v-tab>
                <v-tab key="accounting" v-if="!editable_party">
                  <v-icon left> mdi-shield-account-outline </v-icon>
                </v-tab>
                <v-tab key="map" v-if="!editable_party">
                  <v-icon> mdi-map-search-outline </v-icon>
                </v-tab>
                <v-tab key="photo" v-if="!editable_party">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab
                  key="access"
                  v-if="
                    this.$store.state.profile.user_access === 'Sysop' ||
                    this.$store.state.profile.user_access === 'Admin'
                  "
                >
                  <v-icon> mdi-login </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-icon @click="close()"> mdi-close </v-icon>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="contact">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="item.type_id"
                          :items="setting['type_document_identifications']"
                          item-value="id"
                          item-text="name"
                          label="Tipo de documento"
                          outlined
                          dense
                          hide-details="auto"
                          :readonly="editable_party"
                        />
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field
                          v-model="item.identification_id"
                          label="Numero de documento"
                          hide-details="auto"
                          outlined
                          required
                          dense
                          :clearable="!editable_party"
                          @keyup="validateID"
                          @keypress="filter"
                          @click:append="search_client_by_id"
                          append-icon="mdi-magnify"
                          :readonly="editable_party"
                        >
                          <template v-slot:append-outer v-if="!editable_party">
                            <v-btn x-small @click="get_rut" class="pa-4"> RUT </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.name"
                          id="name"
                          :rules="f_required"
                          label="Nombre"
                          hide-details="auto"
                          required
                          dense
                          outlined
                          @keyup="validateName"
                          @click:append="search_client_by_name"
                          append-icon="mdi-magnify"
                          :readonly="editable_party"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.email"
                          label="Correo Facturación"
                          hide-details="auto"
                          dense
                          outlined
                          :rules="f_required"
                          :readonly="editable_party"
                        />
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field
                          v-model="item.mobile"
                          label="Celular"
                          hide-details="auto"
                          dense
                          type="number"
                          :rules="f_required"
                          outlined
                          append-icon="mdi-phone-outline"
                          @click:append="call_contact(item.mobile)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <v-text-field
                          v-model="item.shipping_email"
                          label="Correo de contácto"
                          hide-details="auto"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" md="4" sm="4">
                        <v-select
                          v-model="item.status"
                          hide-details="auto"
                          :rules="f_required"
                          item-text="text"
                          item-value="value"
                          :items="active_lst"
                          label="Status"
                          outlined
                          dense
                          :readonly="editable_party"
                        />
                      </v-col>

                      <v-col cols="12" md="8" sm="8">
                        <v-text-field
                          v-model="item.address"
                          :rules="f_required"
                          label="Dirección"
                          hide-details="auto"
                          required
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <v-select
                          v-model="item.city"
                          hide-details="auto"
                          label="Ciudad"
                          :items="setting['municipalities']"
                          item-text="name"
                          item-value="code"
                          :rules="f_required"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="branch"
                          hide-details="auto"
                          :rules="f_required"
                          item-value="value"
                          :items="branches"
                          label="Dirección de Entrega"
                          return-object
                          outlined
                          append-outer-icon="mdi-plus"
                          @click:append-outer="addAddress"
                        >
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.name }} - {{ data.item.address }}
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.name }} - {{ data.item.address }}
                          </template>
                        </v-select>
                      </v-col>

                      <!-- <v-col cols="6" md="4">
                <v-combobox
                  v-model="item.country"
                  hide-details="auto"
                  label="País"
                  :items="countries"
                  :rules="f_required"
                  outlined
                  required
                  dense
                  @change="country_change"
                  :disabled="!edit"
                />
              </v-col>
              <v-col cols="6" md="4">
                <v-combobox
                  v-model="item.province"
                  hide-details="auto"
                  label="Provincia"
                  :items="state"
                  :rules="f_required"
                  outlined
                  required
                  dense
                  @change="province_change"
                  :disabled="!edit"
                />
              </v-col> -->
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="accounting">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="item.type_organization_id"
                          :items="setting['type_organizations']"
                          item-text="name"
                          item-value="code"
                          label="Tipo de Organización"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-select
                          v-model="item.type_regime_id"
                          :items="setting['type_regimes']"
                          item-text="name"
                          item-value="id"
                          label="Responsabilidad Fiscal"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.url"
                          label="Pagina Web"
                          hide-details="auto"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-card>
                          <v-card-text v-html="item.note"></v-card-text>
                          <v-card-text>
                            <v-text-field
                              v-model="note"
                              label="Observaciones"
                              hide-details="auto"
                              append-icon="mdi-plus"
                              dense
                              @click:append="addComent"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="map" class="pa-0">
                <div class="map">
                  <StoreMap
                    @store-location="updateLocation"
                    :storeLocation="item.location"
                    :recenter="recenter"
                  />
                </div>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="productPhoto"
                  height="500"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile"
                />
              </v-tab-item>
              <v-tab-item key="access">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          light
                          prepend-inner-icon="mdi-account-circle-outline"
                          background-color="white"
                          v-model="credential.user_name"
                          readonly
                          :rules="f_required"
                          solo
                          required
                          hide-details
                          dense
                          autocomplete
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          light
                          prepend-inner-icon="mdi-email-lock-outline"
                          background-color="white"
                          v-model="credential.user_email"
                          readonly
                          :rules="f_required"
                          solo
                          required
                          hide-details
                          dense
                          autocomplete
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          light
                          prepend-inner-icon="mdi-security"
                          background-color="white"
                          v-model="credential.user_access"
                          readonly
                          :rules="f_required"
                          solo
                          required
                          hide-details
                          dense
                          autocomplete
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          light
                          prepend-inner-icon="mdi-lock-outline"
                          background-color="white"
                          v-model="credential.user_password"
                          type="password"
                          :rules="f_required"
                          solo
                          required
                          hide-details
                          dense
                          autocomplete
                          placeholder="Contraseña"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="createCredential_submit" color="success">
                      <v-icon> mdi-content-save-outline </v-icon>
                      <span> Guardar </span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-btn
                @click="delete_itm(item)"
                :disabled="newItem"
                v-if="
                  $store.state.profile.user_access === 'Sysop' ||
                  $store.state.profile.user_access === 'Admin'
                "
              >
                <span>Borrar</span>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <!-- <v-btn @click="edit = !edit">
            <span>Editar</span>
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn btn-success type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-navigation-drawer> -->
    </v-dialog>
    <v-dialog
      v-model="QRdialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      light
    >
      <v-card tile height="100%">
        <v-card-title class="pa-0">
          <v-app-bar>
            <v-spacer></v-spacer>
            <v-btn x-small color="danger" fab @click="QRdialog = !QRdialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text class="pa-0">
          <StreamBarcodeReader
            @decode="onDecode"
            @loaded="onLoaded"
            :stopCamera="stopCamera"
          />
        </v-card-text>
        <v-card-text class="pa-6">
          <v-row class="fill-height" align="center" justify="center">
            {{ this.result }}
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogShipping"
      max-width="600"
      transition="dialog-bottom-transition"
    >
      <v-form v-model="validAddress" ref="addAddress" @submit="addAddressToAddress">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> Dirección de entrega</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="dialogShipping = !dialogShipping"> mdi-close </v-icon>
          </v-toolbar>
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="branch.name"
                  id="name"
                  :rules="f_required"
                  label="Nombre"
                  hide-details="auto"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="branch.address"
                  :rules="f_required"
                  label="Dirección"
                  hide-details="auto"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="branch.city"
                  hide-details="auto"
                  label="Ciudad"
                  :items="setting['municipalities']"
                  item-text="name"
                  item-value="code"
                  :rules="f_required"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!validAddress" type="submit">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="dialog_clients_list">
      <v-card>
        <v-card-title>
          <span>Contactos</span> <v-spacer></v-spacer
          ><v-icon @click="dialog_clients_list = !dialog_clients_list">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headerClients[$vuetify.breakpoint.xsOnly]"
            :items="clients"
            :items-per-page="10"
            item-key="code"
            mobile-breakpoint="10"
            loader-height="10"
            loading-text="Cargando ..."
            @click:row="selectClient"
            hide-default-header
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:[`item.identification_id`]="{ item }">
              <span>
                <small>{{ item.type_id }}</small>
                <br />
              </span>
              {{ item.identification_id }}
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
              <span v-if="$vuetify.breakpoint.xsOnly">
                <br />
                <small>{{ item.issue_date }}</small>
              </span>
            </template>
            <template v-slot:[`item.note`]="{ item }">
              <span v-html="item.note"></span>
            </template>
            <template v-slot:[`item.city`]="{ item }">
              <span v-if="!$vuetify.breakpoint.xsOnly">
                {{ item.city }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="selectClient(item)">
                mdi-account-edit-outline
              </v-icon>

              <v-icon small class="mr-2" @click="newOrder(item)">
                mdi-file-document-plus-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, fe_ws, getToday, getIssueDate } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
import StoreMap from "../components/Location.vue";
import { StreamBarcodeReader } from "../utils/barcodereader.js";
import settings from "../utils/settings.js";

export default {
  name: "PartyForm",
  components: { StoreMap, StreamBarcodeReader },
  emits: ["updateItm"],
  data() {
    return {
      editable_party: true,
      dialogShipping: false,
      validAddress: false,
      branches: [],
      branch: {
        name: null,
        address: null,
        city: null,
      },
      validCredential: false,
      best_height: "100%",
      best_width: "100%",
      edit: false,
      dialog_clients_list: false,
      sheet: true,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      cities: [],
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      typeaccountsID: [
        { value: "1", text: "Persona Juridica" },
        { value: "2", text: "Persona Natural" },
      ],
      TaxLevelCodes: [
        { value: "48", text: "Responsable del impuesto sobre las ventas" },
        { value: "49", text: "No responsable del impuesto sobre las ventas" },
      ],
      stloc: null,
      note: "",
      setting: settings(),
      headerClients: {
        false: [
          {
            text: "ID",
            align: "center",
            sortable: true,
            value: "identification_id",
            dataType: "number",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      clients: [],
      credential: {
        account: null,
        code: null,
        store_id: null,
        user_email: null,
        user_access: null,
        user_password: "",
        user_name: null,
        token: null,
        token_active: null,
        publickey: null,
        issue_date: null,
      },
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    group_name: {
      type: String,
      required: false,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    groups: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.$store.state.profile.user_access === "Cliente") {
      this.editable_party = true;
    } else {
      this.editable_party = false;
    }
    //this.get_settings();
    // var dta = this.cities;
    // console.log(dta);
    // const keys = Object.keys(dta).sort();
    // this.countries = keys;
    // var stt = this.cities[this.countries[0]];
    // this.state = Object.keys(stt).sort();
    // this.citi=this.cities[this.countries[0]][this.state[0]].sort();
    // this.edit = false;
  },
  methods: {
    addAddress() {
      this.resetAdreess();
      this.dialogShipping = true;
    },
    addAddressToAddress(e) {
      e.preventDefault();
      this.validAddress = this.$refs.addAddress.validate();
      if (this.validAddress) {
        this.branches.push({ ...this.branch });
        this.branches.push.apply(this.branches, { ...this.branch });
        this.item.shipping_address2 = JSON.stringify(this.branches);
        this.resetAdreess();
      }
      this.dialogShipping = false;
    },
    createCredential_submit(e) {
      e.preventDefault;
      if (this.credential.user_password.length < 8) {
        this.$store.dispatch("setToast", {
          timeout_error: 3000,
          dialog: true,
          text: "Debe suministrar una clave de acceso",
          color: "red",
        });
        return;
      }
      var md5 = require("md5");
      this.credential.user_password = md5(this.credential.user_password);
      this.credential.expiration_date = getIssueDate(-5);
      this.credential.issue_date = getToday();
      var qry = {
        account: this.$store.state.profile.account,
        table: "users",
        data: [this.credential],
      };
      this.loading_status = true;
      webserver("put_table", qry, () => {
        this.loading_status = false;
        this.$store.dispatch("setToast", {
          timeout_error: 3000,
          dialog: true,
          text: "Usuario creado",
          color: "success",
        });
      });
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 680) {
        this.best_width = window.innerWidth;
      } else {
        this.best_width = window.innerWidth / 2;
      }
    },
    get_rut() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        method: "POST",
        nit: this.item.identification_id,
        data: [],
      };
      this.loading_status = true;
      fe_ws("get_rut", qry, (data) => {
        this.loading_status = false;
        var rut = JSON.parse(data);
        if (rut.business_name) {
          this.item.name = rut.business_name;
          this.item.type_id = "6";
          this.item.type_organization_id = "1";
          this.item.type_regime_id = "1";
          this.get_fe_email();
        } else {
          if (rut.first_name) {
            this.item.name =
              rut.first_name + " " + rut.surname + " " + rut.second_surname;
            this.item.type_id = "3";
            this.item.type_organization_id = "2";
            this.item.type_regime_id = "2";
            this.get_fe_email();
          } else {
            this.$emit("refresh", { ...createItem() });
            this.$store.dispatch("setToast", {
              timeout_error: 3000,
              dialog: true,
              text: "No se encontró el RUT",
              color: "red",
            });
          }
        }
      });
    },
    get_fe_email() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        method: "POST",
        nit: this.item.identification_id,
        data: [],
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("get_rut_email", qry, (data) => {
        this.loading_status = false;
        console.log(data);
        var rut_email = JSON.parse(data);
        if (rut_email.email) {
          this.item.email = rut_email.email;
        } else {
          this.item.email = "";
        }
      });
    },
    validateID(e) {
      e.preventDefault;
      if (e.keyCode === 13) {
        this.search_client_by_id();
      }
    },
    validateName(e) {
      e.preventDefault;
      if (e.keyCode === 13) {
        this.search_client_by_name();
      }
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    search_client_by_id() {
      // console.log(this.docset);
      // var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      //console.log(keyCode);
      if (this.item.identification_id.length >= 3) {
        var qry = {
          account: this.$store.getters.company.account,
          table: this.table,
          filters: [
            {
              field: "identification_id",
              operator: " LIKE ",
              value: this.item.identification_id,
            },
          ],
        };

        this.clients = [];
        webserver("get_table", qry, (data) => {
          switch (true) {
            case data.length > 1:
              this.dialog_clients_list = true;
              this.clients = data;
              break;
            case data.length === 1:
              this.item = { ...data[0] };
              //this.item = { ...e };
              //this.$emit("refresh", data[0]);
              break;
            default:
              this.$emit("resetForm");
              this.get_rut();
              break;
          }
        });
      }
    },
    search_client_by_name() {
      //e.preventDefault;
      // console.log(this.docset);
      // var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      //console.log(keyCode);
      // if (this.item.name.length >= 3 && keyCode == "13") {
      var qry = {
        account: this.$store.getters.company.account,
        table: this.table,
        filters: [
          {
            field: "name",
            operator: " LIKE ",
            value: this.item.name,
          },
        ],
      };
      // if (this.docset.db) {
      //   qry.filters.push({
      //     field: "group_name",
      //     operator: "=",
      //     value: this.docset.db,
      //   });
      // }
      this.clients = [];
      webserver("get_table", qry, (data) => {
        this.dialog_clients_list = true;
        this.clients = data;
      });
      //}
    },
    selectClient(e) {
      this.item = { ...e };
      //console.log(e);
      //this.$emit("updateItm", e);
      this.clients = [];
      this.dialog_clients_list = false;
    },
    addComent() {
      if (this.note) {
        var currentdate = new Date();
        var datetime =
          currentdate.getDate() +
          "/" +
          (currentdate.getMonth() + 1) +
          "/" +
          currentdate.getFullYear() +
          " @ " +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes() +
          ":" +
          currentdate.getSeconds();

        this.item.note = this.item.note + datetime + ": " + this.note + "<br>";
        this.note = null;
      }
    },
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    re_center() {
      this.recenter = !this.recenter;
    },
    acceptNumber(e) {
      console.log(e);
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, code, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/" +
        this.table +
        "/" +
        src +
        "?" +
        currentdate;
      console.log(url);
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseMenu() {
      this.$refs.menuInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //console.log(">>>>", this.item);
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    onSelectMenu() {
      const input = this.$refs.menuInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.compressed_menu = imageUrl;
            this.menuPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.menus = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.getLocation().then((e) => {
          this.item.account = this.$store.state.profile.account;
          this.item.group_name = this.group_name;
          if (this.item.geodata === null) {
            //console.log(this.item.geodata);
            this.item.geodata = JSON.stringify(e);
            if (this.$store.state.profile.user_access === "Ventas") {
              this.item.agent = this.$store.state.profile.code;
            }
          }
          var qry = {
            account: this.$store.state.profile.account,
            table: this.table,
            data: [this.item],
          };
          if (this.compressed_image) {
            qry.photo = this.compressed_image;
          }
          if (this.compressed_menu) {
            qry.menu = this.compressed_menu;
          }

          this.item.shipping_address = this.branch.address;
          this.item.shipping_name = this.branch.name;
          this.item.shipping_city = this.branch.city;

          this.loading_status = true;
          console.log(qry);
          webserver("put_table", qry, (data) => {
            this.loading_status = false;
            this.$emit("refresh", data);
            this.$emit("close", true);
          });
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", false);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    country_change(e) {
      this.state = [];
      this.city = [];
      this.item.province = null;
      this.item.city = null;
      var stt = this.cities[e];
      this.state = Object.keys(stt).sort();
    },
    province_change(e) {
      this.city = [];
      this.item.city = null;
      this.city = this.cities[this.item.country][e].sort();
    },
    updateLocation(e) {
      this.item.location = JSON.stringify(e);
    },
    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
    onLoaded(e) {
      console.log(e);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
    resetAdreess() {
      this.branch = {
        name: null,
        address: null,
        city: null,
      };
    },
  },
  watch: {
    item: function (e) {
      this.credential = {
        account: e.account,
        code: e.code,
        store_id: e.store,
        user_email: e.email,
        user_password: "",
        user_name: e.name,
        user_id: e.identification_id,
      };
      this.branches = [];

      if (this.item.shipping_address2) {
        this.branches.push.apply(this.branches, JSON.parse(this.item.shipping_address2));
      }
      switch (this.table) {
        case "stores":
          this.credential.user_access = "Admin";
          break;
        case "sellers":
          this.credential.user_access = "Ventas";
          break;
        case "suppliers":
          this.credential.user_access = "Proveedor";
          break;
        default:
          this.credential.user_access = "Cliente";
          break;
      }
      if (!e.city) {
        e.city = this.$store.state.company.city;
      }
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, e.code, e.picture);
      } else {
        this.productPhoto = null;
      }
      if (this.newItem) {
        this.edit = true;
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 200px;
}
</style>
